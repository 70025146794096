import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ChoiceElementProps from "components/ChoiceElement/ChoiceElementProps";

const Radio = loadable(
    (): Promise<DefaultComponent<ChoiceElementProps>> =>
        import(/* webpackChunkName: "Radio" */ "./Radio"),
);
const RadioLoader = (props: ChoiceElementProps): ReactElement => (
    <Radio {...props} />
);
export default RadioLoader;
