import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import ChoiceElementProps from "components/ChoiceElement/ChoiceElementProps";

const Checkbox = loadable(
    (): Promise<DefaultComponent<ChoiceElementProps>> =>
        import(/* webpackChunkName: "Checkbox" */ "./Checkbox"),
);
const CheckboxLoader = (props: ChoiceElementProps): ReactElement => (
    <Checkbox {...props} />
);
export default CheckboxLoader;
